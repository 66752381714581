<template>
    <div style="height: 300px">
        <line-chart id="research-secondary" :chart-options="chartOptions" :chart-data="chartData" :plugins="plugins">Failed to load.</line-chart>
    </div>
</template>
<script>
    import { ref, inject, computed, onMounted } from "vue";
    import lineChart from "@/components/research/line-chart.vue";
    import functions from "@/components/research/functions.js";
    import { toFont } from 'chart.js/helpers';
    import externalTooltip from "@/components/research/externalTooltip.js";

    export default {
        components: {
            lineChart
        },
        props: {
            startDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date(new Date().getFullYear() - 1, 1, 1)
                }
            },
            endDate: {
                type: [Date, String, Object],
                default: () => {
                    return new Date()
                }
            },
            customOptions: {
                type: Object,
                default: () => {
                    return { avgRange: 12, hideTooltip: false }
                }
            },
            rawData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            plugins: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        setup: (props, context) => {
            const moment = inject("$moment");



            const data = computed(() => {
                if (!props.startDate || !props.endDate) return props.rawData;
                else {
                    return props.rawData.filter(d => props.startDate <= moment(d["related_date"], "YYYY-MM-DD").toDate() && moment(d["related_date"], "YYYY-MM-DD").toDate() <= props.endDate);
                }
            });

            function findYScaleMin(data) {
                var negativeFR = data.map(d => functions.forward_return(d, data)).filter(d => d < 0);
                var negativeValue = data.map(d => functions.abs_reversion(d)).filter(d => d < 0);
                var negativeGrowth = data.map(d => functions.growth_reversion(d)).filter(d => d < 0);

                var negatives = negativeFR.concat(negativeValue, negativeGrowth).sort((a, b) => b - a);

                var excludeIndex = Math.floor(negatives.length * 0.05);
                var percentile = negatives[negatives.length - excludeIndex - 1];

                var rounded = Math.round(percentile * 2) / 2;
                rounded = Math.max(rounded, -1);
                rounded = Math.min(rounded, -.5);
                return rounded < -1 ? -1 : rounded;
            }

            function findYScaleMax(data) {
                var positiveFR = data.map(d => functions.forward_return(d, data)).filter(d => d > 0);
                var positiveValue = data.map(d => functions.abs_reversion(d)).filter(d => d > 0);
                var positiveGrowth = data.map(d => functions.growth_reversion(d)).filter(d => d > 0);

                var positives = positiveFR.concat(positiveValue, positiveGrowth).sort((a, b) => a - b);

                var excludeIndex = Math.floor(positives.length * 0.05);
                var percentile = positives[positives.length - excludeIndex - 1];

                var rounded = Math.round(percentile * 2) / 2;
                return rounded >= 2.5 ? 2.5 : rounded;
            }

            const chartOptions = computed(() => {
                return {
                    z_index: 2,
                    name: "Last Chart",
                    responsive: true,
                    maintainAspectRatio: false,
                    //aspectRatio: 4 / 1,
                    scales: {
                        x: {
                            ticks: {
                                callback: function (val, index) {
                                    const parts = this.getLabelForValue(val).split('-');
                                    const d = new Date(parts[0], parts[1] - 1, parts[2]);
                                    const format = moment(d).format("YYYY");
                                    return format;
                                }
                            }
                        },
                        y_right: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            min: findYScaleMin(data.value),
                            max: findYScaleMax(data.value),
                            afterFit: function (scaleInstance) {
                                scaleInstance.width = 100;
                            },
                            grid: {
                                lineWidth: function (context) {
                                    if (context.tick.value == 0) return 1;
                                    else return 1;
                                },
                                color: function (context) {
                                    if (context.tick.value == 0) return 'black';
                                    else return 'rgba(0, 0, 0, 0.1)';
                                }
                            }
                        }
                    },
                    interaction: {
                        mode: 'index',
                        intersect: false
                    },
                    plugins: {
                        legend: {
                            position: "bottom"
                        },
                        tooltip: {
                            enabled: false,
                            position: "nearest",
                            hideTooltip: props.customOptions?.hideTooltip == true,
                            external: externalTooltip
                        }
                    },
                    layout: {
                        padding: {
                            left: 50
                        }
                    }
                }
            });

            const chartData = computed(() => {
                return {
                    labels: data.value.map(d => d["related_date"]),
                    datasets: [
                        {
                            label: "Forward Return",
                            fill: false,
                            borderColor: "blue",
                            data: data.value.map(d => functions.forward_return(d, props.rawData)),
                            yAxisID: 'y_right',
                            pointRadius: 0.1
                        },
                        {
                            label: "Relative (S&P)",
                            fill: false,
                            borderColor: "violet",
                            data: data.value.map(d => functions.pe_reversion(d)),
                            yAxisID: 'y_right',
                            pointRadius: 0.1
                        },
                        {
                            label: "Value",
                            fill: false,
                            borderColor: "purple",
                            data: data.value.map(d => functions.abs_reversion(d)),
                            yAxisID: 'y_right',
                            pointRadius: 0.1
                        },
                        {
                            label: "Growth",
                            fill: false,
                            borderColor: "cyan",
                            data: data.value.map(d => functions.growth_reversion(d)),
                            yAxisID: 'y_right',
                            pointRadius: 0.1
                        },
                    ]
                }
            });

            return {
                chartOptions,
                chartData
            }
        }
    }
</script>
