<template>
    <o-loading :active="loading">Loading</o-loading>
    <div class="is-flex is-justify-content-space-between" style="font-size: 13px;">
        <div style="min-width: 200px">
            <o-field label="Ticker" ref="ticker_ref">
                <o-autocomplete style="font-size: 12px;" v-model="ticker" clearable open-on-focus :data="filteredTickers" @select="GetData" :disabled="loading">
                    <template #empty>
                        No results.
                    </template>
                </o-autocomplete>
            </o-field>
        </div>
        <div style="min-width: 100px">
            <o-field label="Start Date">
                <o-select v-model="startDate" @change="SetQuery()" expanded>
                    <option v-for="(d, i) in periods" :key="i" :value="d">{{ d.format("MMM YYYY") }}</option>
                </o-select>
            </o-field>
        </div>
        <div style="min-width: 100px">
            <o-field label="End Date">
                <o-select v-model="endDate" @change="SetQuery()" expanded>
                    <option v-for="(d, i) in periods" :key="i" :value="d">{{ d.format("MMM YYYY") }}</option>
                </o-select>
            </o-field>
        </div>
        <div>
            <o-field label="Year ranges">
                <o-button size="small" @click="SetDateRange(5)">5</o-button>
                <o-button size="small" @click="SetDateRange(15)">15</o-button>
                <o-button size="small" @click="SetDateRange(35)">35</o-button>
                <o-button size="small" @click="SetDateRange()">Max</o-button>
            </o-field>
        </div>
        <div>
            <o-field label="Avg Range">
                <o-select placeholder="12" @change="SetQuery()" @input="ChangedAvgRange($event.target.value)" expanded>
                    <option :value="12">12</option>
                    <option :value="18">18</option>
                    <option :value="24">24</option>
                    <option :value="30">30</option>
                    <option :value="36">36</option>
                </o-select>
            </o-field>
        </div>
        <div>
            <o-field label="Hide tooltip">
                <o-switch v-model="hideTooltip"></o-switch>
            </o-field>
        </div>
    </div>
    <div class="mt-4" style="font-size: 14px" v-if="ticker">
        <o-collapse :open="false">
            <template #trigger>
                Toggle tables
            </template>
            <growth-table :start-date="startDate" :end-date="endDate" :raw-data="data" :industryData="industryData" :indexData="indexData" :rawReleases="releases" :custom-options="customOptions"></growth-table>
        </o-collapse>
    </div>
    <div style="position: relative;" v-if="ticker">
        <primary-chart :start-date="startDate" :end-date="endDate" :raw-data="data" :transactions="transactions" :custom-options="customOptions" :plugins="[sharePlugin, verticalLine]"></primary-chart>
        <tertiary-chart :start-date="startDate" :end-date="endDate" :raw-data="data" :deals="deals" :custom-options="customOptions" :plugins="[sharePlugin, verticalLine]"></tertiary-chart>
    </div>
    <div v-if="ticker">
        <secondary-chart :start-date="startDate" :end-date="endDate" :raw-data="data" :custom-options="customOptions" :plugins="[sharePlugin, verticalLine]"></secondary-chart>
    </div>

    <div v-if="!ticker" class="mt-3">
        <ag-grid-vue class="ag-theme-alpine" :loading="loading" :rowData="griddata" :defaultColDef="defaultColDef" domLayout="500" style="height: 55rem" :columnDefs="columnDefs" :gridOptions="gridOptions" @grid-ready="onGridReady"></ag-grid-vue>
    </div>

</template>
<script>
    import { ref, inject, computed, watch, onMounted, onBeforeMount } from "vue";
    import primaryChart from "@/components/research/primary-chart.vue";
    import secondaryChart from "@/components/research/secondary-chart.vue";
    import growthTable from "@/components/research/growth-table.vue";
    import tertiaryChart from "@/components/research/tertiary-chart.vue";
    import { sharePlugin } from "@/components/research/plugins/share-plugin.js";
    import { verticalLine } from "@/components/research/plugins/vertical-line.js";
    import { transparentBackground } from "@/components/research/plugins/transparent-background.js";
    import shared from "@/components/research/shared.js";
    import { useRoute, useRouter } from "vue-router";
    import { ServerSideTransactionResultStatus } from "ag-grid-community";
    import { AgGridVue } from "ag-grid-vue3";
    import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
    import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
    import "ag-grid-enterprise";

    document.title = "Research";

    export default {
        components: {
            primaryChart,
            secondaryChart,
            growthTable,
            tertiaryChart,
            AgGridVue
        },
        setup(props, context) {
            const http = inject("$http");
            const moment = inject("$moment");
            const router = useRouter();
            const route = useRoute();

            const loading = ref(false);
            const ticker = ref("");
            const tickers = ref([]);
            const startDate = ref(moment("01/01/1960", "MM/DD/YYYY").toDate());
            const endDate = ref(moment().toDate());
            const avgRange = ref();
            const hideTooltip = ref();
            const data = ref([]);
            const transactions = ref([]);
            const deals = ref([]);
            const industryData = ref([]);
            const indexData = ref([]);
            const releases = ref([]);
            const gridApi = ref({});
            const griddata = ref([]);

            const ticker_ref = ref();

            const percentFormatter = function (params) {
                if (params.value == null) return "0%";
                params.value *= 100;
                return formatNumber(params, 0) + "%";
            };

            const currencyFormatter = function (params) {
                if (params.value == null) return "";
                return "$" + formatNumber(params, 0);
            };

            const formatNumber = function (params, precision = 0) {
                if (params.value == null) return "";
                return parseFloat(params.value)
                    .toFixed(precision)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            };
            ``
            const singleDecimalFormatter = function (params) {
                if (params.value == null) return "";
                return formatNumber(params, 1);
            };

            const defaultColDef = {
                resizible: true,
                sortable: true,
                width: 90,
            };

            const median = (g) => {
                const values = g.values.sort(function (a, b) {
                    return a - b;
                });

                if (values.length == 0) return 0;
                else if (values.length % 2 == 0) {
                    const i = Math.floor(values.length / 2);
                    const a = values[i - 1];
                    const b = values[i];
                    return (a + b) / 2
                }
                else {
                    const i = Math.floor(values.length / 2);
                    return values[i];
                }
            }

            const tooltip = {
                var: "For each company we look at monthly returns compared to the median return of their industry. The trailing one year average of these variances is the VAR value.  Avg(Abs(historical_archive.total return-industry.total return...))",
                marketCap: "Market Cap",
                pe: "This is the trailing P/E Ratio.",
                pe_est: "This is the P/E Ratio based on the forward earnings estimate.",
                pSales: "This is the P/Sales Ratio based on the forward sales estimate and it is based on the enterprise value of a company, rather than the market cap, so debt will raise the ratio and cash will lower it.",
                threeYearRet: "Three Year Return",
                oneYearRet: "One Year Return",
                orgGrowth: "Future Sales Growth.  It is calculated by looking at sales estimates two years from now divided by sales estimates one year from now.  This far-reaching look eliminates noise connected to acquisitions and divestments, which would come from looking at sales estimates divided by trailing results.",
                rel: "This is the reversion calculated on a relative basis against the S&P 500.  It looks at a 2-year forward P/E ratio for a company and the S&P and compares that to the five-year trailing relationship between the two.  I think it is best applied to large and significant companies or entire industries that are themselves good proxies for the S&P.",
                value: "This looks at three values; a company's reversion based on its trailing P/E against 15 years of history, a company's reversion based on its forward P/E against 5 years of history, and a company's reversion based on its forward P/Sales against 5 years of history.  The 'Value' reversion is the median value of these three inputs.  I think it is best applied to all companies and industries that are not experiencing rapid change, either decelerating or accelerating growth beyond historic norms.",
                stdev: "",
                growth: "We calculate a version of a PEG ratio, but in this case we are looking at Enterprise Value/Estimated Gross Profits or Operating Profits/Organic Sales Growth.  The current ratio is compared to 5 years of history.  I think it is best applied to companies and industries experiencing rapid change.  For example, a slowing tech company with falling valuations will be better handled by this growth reversion than either Relative or Value reversions.  The math changes significantly though with small changes to forward sales growth estimate, so this reversion figure should be used with caution.",
                momentum: "This ranks all companies in the system based on three month stock returns and three month changes to earnings estimates.  Companies and industries with a high momentum score (near 100%) will have shown the best returns and the largest improvement to earnings estimates.",
                score: "",
            };

            const columnDefs = ref([
                { headerName: "Industry", field: "Industry", minWidth: 300, pinned: "left", rowGroup: true, hide: true },
                {
                    headerName: "Ticker",
                    field: "Link",
                    cellRenderer: params => {
                        return params.value ? `<a target="_blank" href="/research?ticker=${params.value}">${params.value}</a>` : ''
                    },
                    headerTooltip: "Ticker (click to view)"
                },
                { headerName: "Var", field: "Var", valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.var },
                { headerName: "MarketCap", field: "MarketCap", valueFormatter: currencyFormatter, type: "rightAligned", aggFunc: 'sum', headerTooltip: tooltip.marketCap },
                { headerName: "PE", field: "PE", valueFormatter: singleDecimalFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.pe },
                { headerName: "PE_est", field: "PE_est", valueFormatter: singleDecimalFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.pe_est },
                { headerName: "PSales", field: "PSales", maxWidth: 120, valueFormatter: singleDecimalFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.pSales },
                { headerName: "ThreeYearRet", field: "ThreeYearRet", maxWidth: 140, valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.threeYearRet },
                { headerName: "OneYearRet", field: "OneYearRet", maxWidth: 140, valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.oneYearRet },
                { headerName: "OrgGrowth", field: "OrgGrowth", maxWidth: 140, valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.orgGrowth },
                { headerName: "Rel", field: "Rel", valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.rel },
                { headerName: "Value", field: "Value", maxWidth: 100, valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.value },
                { headerName: "STDEV", field: "STDEV", maxWidth: 100, valueFormatter: singleDecimalFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.stdev },
                { headerName: "Growth", field: "Growth", maxWidth: 110, valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.growth },
                { headerName: "Score", field: "Score", maxWidth: 100, valueFormatter: singleDecimalFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.score },
                { headerName: "Momentum", field: "Momentum", maxWidth: 140, valueFormatter: percentFormatter, type: "rightAligned", aggFunc: median, headerTooltip: tooltip.momentum },

                //{
                //    headerName: "Links",
                //    field: "Links",
                //    minWidth: 700,
                //    cellRenderer: params => {
                //        return params?.value?.map(ticker => {
                //            let href = `https://datastudio.google.com/u/0/reporting/98cad188-6983-4346-8e11-8751d89a0a3e?params=%7B%22ds0.psc_ticker%22%3A%22${ticker}%22%7D`;
                //            return `<a href="${href}" target="_blank">${ticker}</a>`
                //        }).join(", ");
                //    }
                //}
            ]);
            const gridOptions = ref({

                autoGroupColumnDef: {
                    headerName: "Industry",
                    field: "Industry",
                    minWidth: 300,
                    pinned: "left",
                    headerTooltip: "Industry Group"
                },
                suppressAggFuncInHeader: true,
                tooltipShowDelay: 200
            });

            const startYearSelection = ref();

            const periods = computed(() => {
                return Array.from(new Set(data.value.map(d => moment(d["related_date"]))));
            });

            const dates = computed(() => {
                return Array.from(new Set(data.value.map(d => moment().diff(moment(d["related_date"], "MM/DD/YYYY"), 'years')))).map(d => { return { year: d } }).filter(x => x.year > 0).reverse();
            });
            const filteredTickers = computed(() => {
                return tickers.value.filter(t => t.toLowerCase().indexOf(ticker.value.toLowerCase()) > -1);
            });

            const customOptions = computed(() => {
                return {
                    avgRange: avgRange.value,
                    hideTooltip: hideTooltip.value
                }
            });
            const ApplyQueryString = () => {
                if (route.query.ticker) ticker.value = route.query.ticker.toString() || ticker.value;
                if (route.query.startDate) startDate.value = route.query.startDate ? moment(route.query.startDate, 'MM-DD-YYYY') : startDate.value;
                if (route.query.endDate) endDate.value = route.query.endDate ? moment(route.query.endDate, 'MM-DD-YYYY') : endDate.value;
                if (route.query.avgRange) avgRange.value = route.query.avgRange.toString() || avgRange.value;
            }
            const SetQuery = (clear = false) => {
                if (clear)
                    router.replace({ path: route.path });
                else
                    router.replace({ path: route.path, query: newQueryObj.value });
            }

            //const queryString = computed(() => {
            //    const currentRoute = useRoute();
            //    const qs = Object.keys(currentRoute.query).map(key => `${key}=${currentRoute.query[key]}`).join("&");
            //    return qs.length == 0 ? "" : `?${qs}`;
            //});
            const newQueryObj = computed(() => {
                return {
                    ticker: ticker.value,
                    startDate: moment(startDate.value).format("MM-DD-YYYY"),
                    endDate: moment(endDate.value).format("MM-DD-YYYY"),
                    avgRange: avgRange.value
                };
            })

            const debounce = function (func, delay) {
                let timeoutId;
                return async function (...args) {
                    clearTimeout(timeoutId);

                    return new Promise((resolve) => {
                        timeoutId = setTimeout(async () => {
                            await func.apply(this, args);
                            resolve();
                        }, delay);
                    });
                };
            };

            const ChangedAvgRange = (val) => {
                debounce(() => {
                    avgRange.value = val;
                    SetQuery();
                }, 500)();
            }

            const SetDateRange = (d) => {
                if (d > 0) {
                    var flag = false;
                    var dte = moment().subtract(d, 'years').toDate();
                    periods.value.forEach(p => {
                        if (!flag && moment(p) > dte) {
                            startDate.value = p;
                            endDate.value = periods.value[periods.value.length - 1];
                            flag = true;
                        }
                    });
                    SetQuery();
                }
                else {
                    startDate.value = periods.value[0];
                    endDate.value = periods.value[periods.value.length - 1];
                }

                startYearSelection.value = d;
            }

            const GetData = async (val) => {
                SetQuery(true);
                if (!val) return;

                loading.value = true;

                try {
                    const response = await http.get("/api/scoring/data?ticker=" + encodeURIComponent(val));
                    data.value = response.data.data;
                    transactions.value = response.data.transactions;
                    deals.value = response.data.deals;
                    industryData.value = response.data.industryData;
                    indexData.value = response.data.indexData;
                    releases.value = response.data.releases;

                    SetDateRange();
                    ApplyQueryString();
                    SetQuery();
                }
                catch (err) {
                    console.error(err);
                }
                loading.value = false;
            }

            const GetTickers = async (val) => {
                try {
                    const response = await http.get("/api/scoring/tickers");
                    tickers.value = response.data;
                }
                catch (err) {
                    console.error(err);
                }
            }

            const LoadGrid = async () => {
                try {
                    loading.value = true;
                    const response = await http.get("/api/gdrive/research");
                    griddata.value = response.data;
                    loading.value = false;
                }
                catch (err) {
                    console.error(err);
                    loading.value = false;
                }
            }

            const onGridReady = (params) => {
                gridApi.value = params.api;

                params.columnApi.autoSizeAllColumns();
            }

            watch(() => newQueryObj.value, (n, o) => {
                //console.log(n, o);
                //SetQuery();
            });



            onBeforeMount(() => {
                //griddata.value = [{
                //    Industry: "Test1",
                //    Count: 2,
                //    Links: ["https://facebook.com", "https://google.com"]
                //}]; //TestData
                ApplyQueryString();
            });

            onMounted(() => {
                if (Object.keys(route.query).length == 0) {
                    LoadGrid();
                }

                GetTickers();
                if (ticker.value)
                    GetData(ticker.value);
            });


            return {
                loading,
                ticker,
                tickers,
                startDate,
                endDate,
                avgRange,
                hideTooltip,
                dates,
                periods,
                startYearSelection,
                data,
                customOptions,
                defaultColDef,
                filteredTickers,
                ChangedAvgRange,
                SetDateRange,
                GetData,
                sharePlugin,
                verticalLine,
                transparentBackground,
                SetQuery,
                transactions,
                deals,
                industryData,
                indexData,
                releases,
                griddata,
                columnDefs,
                onGridReady,
                gridOptions,
                ticker_ref
            }
        }
    }
</script>
